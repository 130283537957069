<template>
  <div v-if="Object.keys(userInfo).length && wallet">
    <div class="gold" @click="show">
      <img src="https://cdn.001ppt.cn/h5/assets/images/gold.png"/>
      <span>{{totalRemainValue}}</span>
    </div>

    <recommend-dialog ref="model" @share="$emit('share')"/>
  </div>
</template>

---------------------- script -----------------------
<script>
  import model from '@/components/model/model'
  import {mapGetters} from 'vuex'
  import RecommendDialog from "../../../components/biz/RecommendDialog";

  export default {
    components: {RecommendDialog, model},
    computed: {
      ...mapGetters(['userInfo', 'wallet']),
      totalRemainValue() {
        const {wallet} = this;
        if (Object.keys(this.userInfo).length && wallet && wallet.totalRemainValue) {
          return wallet.totalRemainValue > 999 ? '999+' : parseInt(wallet.totalRemainValue);
        } else {
          return 0;
        }
      }
    },


    methods: {
      show() {
        if (!Object.keys(this.userInfo).length) {
          // return this.$router.push({path: '/login/wechat'});
          return this.$store.dispatch('showLoginDialog');
        }
        this.$refs['model'].toggleDialog();
      },
    }
  }
</script>


------------------------------- style --------------------------
<style lang="less" scoped>
  .gold {
    //position: fixed;
    left: 8px;
    bottom: 150px;
    height: 50px;
    width: 40px;
    border-radius: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gold img {
    width: 25px;
    height: 25px;
  }

  .gold span {
    font-weight: bold;
    margin-top: 2px;
    font-size: 10px;
  }
</style>
