<template>
  <div class="home" ref="home" id="home">
    <home-header ref="homeHeader"/>
    <keep-alive>
      <router-view/>
    </keep-alive>

    <transition name="van-slide-right">
      <div class="home-drawer" v-if="visibles.homeDrawer && $route.path==='/'">
        <ticket-dialog/>
        <div class="home-drawer-close" @click="visibles.homeDrawer=false">
          <van-icon name="cross" size="12" color="#fff"/>
        </div>
        <home-share style="margin-bottom: 5px" ref="homeShare" :recommend-award-yanzhi="config.recommendAwardYanzhi"
                    @share="()=>this.$refs.homeHeader.showShare()"/>
        <!--    <home-recommend :recommend-award-yanzhi="config.recommendAwardYanzhi" :invite-data="$store.state.share.inviteData"-->
        <!--                    @showShare="()=>this.$refs['homeShare'].show()"/>-->
        <div class="yanruyu" @click="$refs.gzhDialog.show()">
          <img src="@/assets/svg/customer-service.svg" alt="">
        </div>
      </div>
    </transition>

    <gzh-dialog ref="gzhDialog" :type="2"/>
    <notify-join-dialog ref="joinMemberGzhDialog"/>
    <notify-expire-dialog ref="expireDialog" @share="$refs.homeHeader.showShare()"/>

  </div>
</template>

<script>
import homeHeader from "./components/homeHeader.vue";
import HomeShare from "./components/homeShare.vue";
import TicketDialog from "../../components/biz/TicketDialog.vue";
import {taskV2Api} from "../../api/v2/taskV2Api";
import NotifyExpireDialog from "./components/NotifyExpireDialog.vue";
import GzhDialog from "../../components/GzhDialog.vue";
import NotifyJoinDialog from "./components/NotifyJoinDialog.vue";
import {mapGetters} from "vuex";
import {user} from '@/api'


export default {
  components: {NotifyJoinDialog, GzhDialog, NotifyExpireDialog, TicketDialog, HomeShare, homeHeader},

  data() {
    return {
      visibles: {
        homeDrawer: true
      },
      config: {
        recommendAwardYanzhi: 0
      },
      expireVip: {}
    }
  },


  mounted() {
    this.loadRecommendAwardYanzhi()
    this.tryShowGzhDialog();
    this.$store.dispatch('getMemberInfo')
  },


  methods: {
    // 加载首次奖励严值
    async loadRecommendAwardYanzhi() {
      const res = await taskV2Api.findById(6);
      this.config.recommendAwardYanzhi = res.value;
    },


    async tryShowGzhDialog() {
      const res = await user.memberInfo();
      if (res.code === 1) {
        if(this.$refs.expireDialog) {
          this.$refs.expireDialog.tryShow(res.data).then()
        }
        if(this.$refs.joinMemberGzhDialog){
          this.$refs.joinMemberGzhDialog.tryShow(res.data).then()
        }
      }
    },

  }

}
</script>

<style lang="less" scoped>
.home-drawer {
  position: fixed;
  right: 10px;
  bottom: 200px;
  width: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  //border: 1px solid #f4f4f4;
  //background: #eee;
  //overflow: hidden;
  z-index: 1000;
  padding-top: 5px;
}

.home-drawer-close {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  border-radius: 100px;
  top: -5px;
  right: -5px;
}

.home-drawer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #ddd;
  opacity: .5;
  border-radius: 100px;
  z-index: -1;
}

.yanruyu {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  //position: fixed;
  left: 8px;
  bottom: 113px;

  img {
    height: 24px;
    width: 24px;
    border-radius: 100px;
  }
}
</style>
