<template>
  <model ref="expireDialog">
    <div>
      <div class="expire-icon">
        <img src="@/assets/svg/cry.svg" alt="">
      </div>
      <div class="expire-main-title">
        <template v-if="expireVip.vipRightId === '1'">1天试用会员</template>
        <template v-if="expireVip.vipRightId === '2'">入门会员</template>
        <template v-if="expireVip.vipRightId === '4'">超级VIP会员</template>
        已到期
      </div>
      <div class="expire-sub-title">已恢复普通用户身份，你还可以：</div>
      <div class="buttons">
        <div class="button" @click="showShare">邀请好友继续获得</div>
        <div class="button primary" @click="$router.push('/member')">
          <template v-if="expireVip.vipRightId!=='4'">正式加入超级VIP</template>
          <template v-else>续费超级VIP</template>
        </div>
      </div>
    </div>
  </model>
</template>

<script>
import Model from "../../../components/model/model";
import {vipRightV2Api} from "../../../api/v2/vipRightV2Api";


export default {
  components: {Model},
  data() {
    return {
      expireVip: {
        vipRightId: ''
      }
    }
  },

  methods: {
    async tryShow(currVip) {
      // if (currVip.vipRightId === '3') {
      //   const expireVip = await vipRightV2Api.findMyLastExpireVip();
      //   if (expireVip && !expireVip.lastNotifyExpireTime) {
      //     this.expireVip = expireVip;
      //     this.$refs.expireDialog.show(expireVip);
      //     vipRightV2Api.notifyExpire(expireVip.id);
      //   }
      // }
    },


    showShare() {
      this.$refs.expireDialog.hide()
      this.$emit('share')
    }
  }
}
</script>

<style scoped lang="less">

.expire-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  img {
    width: 100px;
  }
}

.expire-main-title {
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  color: #151d36;
}

.expire-sub-title {
  font-size: 14px;
  color: #151d36;
  opacity: .3;
  text-align: center;
  height: 32px;
  line-height: 32px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  height: 40px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #f3f3f5;
  font-size: 12px;

  &.primary {
    background: #151d36;
    color: #fff;
  }
}

</style>
