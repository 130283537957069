<template>
  <model ref="joinMemberGzhDialog">
    <div class="gzh-dialog">
      <div class="gzh-dialog-title">{{ title }}</div>
      <div class="pxBorder"></div>
      <div v-html="content"></div>
    </div>
  </model>
</template>

<script>
import {vipRightV2Api} from "../../../api/v2/vipRightV2Api";
import Model from "../../../components/model/model";

export default {

  components: {
    Model
  },

  data() {
    return {
      title: '',
      content: ''
    }
  },


  methods: {

    async tryShow(vip) {
      if ((vip.vipRightId === '5' || vip.vipRightId === '4' || vip.vipRightId === '2') && !vip.lastNotifyJoinTime) {
        const res = await vipRightV2Api.findNotifyDialog(vip.vipRightId)
        if (res) {
          this.content = res.notifyDialogContent;
          this.title = res.notifyDialogTitle;
          this.$refs.joinMemberGzhDialog.show({vip});
        }
        vipRightV2Api.notifyJoin(vip.id);
      }
    }

  }

}
</script>

<style scoped lang="less">
.gzh-dialog-title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 32px;
  font-weight: bolder;
  color: #151d36;
}
</style>
