<template>
  <div>
    <div class="ticket-icon" @click="show" v-if="popoverVisible">
      <img src="@/assets/svg/gift.svg" alt="">
    </div>
    <div class="ticket-dialog" v-if="visible">
      <div class="ticket-shade"></div>
      <div class="ticket">
        <div class="ticket-title">
          {{ ticketConfig.title }}
        </div>
        <div class="ticket-sub-title">{{ ticketConfig.subTitle }}</div>
        <img class="ticket-printer" src="https://cdn.001ppt.cn/pc/img/ticket-printer.png" alt="">
        <div class="ticket-desc">
          <div class="ticket-desc-discount">-¥{{ ticket.money }}</div>
          <div class="ticket-desc-content">{{ ticketConfig.moneyDesc }}</div>
        </div>
        <div class="ticket-countdown-title">
          {{ ticketConfig.countDownTitle }}
        </div>
        <div class="ticket-countdown">
          <div class="ticket-countdown-box">
            <div class="ticket-countdown-block">{{ countdown.hours }}</div>
            <div class="ticket-countdown-unit">时</div>
            <div class="ticket-countdown-block">{{ countdown.minutes }}</div>
            <div class="ticket-countdown-unit">分</div>
            <div class="ticket-countdown-block">{{ countdown.seconds }}</div>
            <div class="ticket-countdown-unit">秒</div>
            <div class="ticket-countdown-block">{{ countdown.ms }}</div>
            <div class="ticket-countdown-unit">毫秒</div>
          </div>
        </div>
        <div class="ticket-btn" @click="goMember">
          <button>{{ ticketConfig.buttonText }}</button>
        </div>
        <div class="ticket-close-btn">
          <button @click="confirmClose">
            <van-icon name="cross" color="#fff" size="14px"/>
          </button>
        </div>
      </div>
    </div>
    <model ref="closeModel">
      <div class="close-model">
        <div class="close-model-img">
          <img src="@/assets/svg/cry.svg" alt="">
        </div>
        <div class="close-model-title">主人请三思</div>
        <div class="close-model-sub-title">{{ ticketConfig.confirmExitText }}
        </div>
        <div class="close-model-btns">
          <button @click="hide">忍心放弃</button>
          <button class="primary" @click="hideCloseModel">返回看看</button>
        </div>
      </div>
    </model>
  </div>
</template>


-------------------- script --------------------

<script>
import {ticketV2Api} from "../../api/v2/ticketV2Api";
import {mapGetters} from 'vuex'
import model from '@/components/model/model'

export default {
  computed: {
    ...mapGetters(['userInfo', 'userVip']),
    ticket() {
      return this.$store.getters["ticket/currTicket"]
    },
  },
  components: {model},

  data() {
    return {
      popoverVisible: false,
      visible: false,
      countdown: {
        deltaMs: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        ms: 0,
        interval: null
      },
      ticketConfig: {}
    }
  },

  async created() {
    if (!this.userInfo || !Object.keys(this.userInfo).length) {
      return;
    }
    // const res = await ticketV2Api.findMy();
    await this.$store.dispatch('ticket/loadAllTickets')
    const res = this.ticket

    if (res) {
      let expireTime = res.expireTime
      if(Object.prototype.toString.call(expireTime) === '[object String]'){
        expireTime = new Date(expireTime)
      }
      if (expireTime.getTime() > Date.now() && !res.hasUsed) {
        this.startCountDown();
        this.popoverVisible = true;
        if (!res.hasShown) {
          this.show();
          ticketV2Api.updateMyHasShown(res.id).then();
        }
      }
      this.ticket = res;
      if (this.ticket) {
        this.ticketConfig = (await ticketV2Api.findConfigByType(this.ticket.type)) || {};
      }
    }
  },

  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
      this.hideCloseModel();
    },

    goMember() {
      this.$router.push('/member?id='+this.ticket.vipRightId);
      this.hide();
    },

    confirmClose() {
      this.$refs.closeModel.toggleDialog();
    },

    hideCloseModel() {
      this.$refs.closeModel.closeModal();
    },


    startCountDown() {
      this.countdown.interval = setInterval(() => {
        let expireTime = this.ticket.expireTime;
        if(Object.prototype.toString.call(expireTime) === '[object String]'){
          expireTime = new Date(expireTime)
        }
        const deltaMs = expireTime.getTime() - Date.now();
        if (deltaMs <= 0) {
          this.stopCountDown();
        }

        const hours = Math.floor(deltaMs / (60 * 60 * 1000));
        const minutes = Math.floor((deltaMs - (hours * 60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((deltaMs - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / 1000);
        let ms = Math.floor((deltaMs - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000) - (seconds * 1000)) / 10);
        ms = ms === 100 ? 99 : ms;
        this.countdown.deltaMs = deltaMs;
        this.countdown.hours = hours < 10 ? ('0' + hours) : hours;
        this.countdown.minutes = minutes < 10 ? ('0' + minutes) : minutes;
        this.countdown.seconds = seconds < 10 ? ('0' + seconds) : seconds;
        this.countdown.ms = ms < 10 ? ('0' + ms) : ms;
      }, 50)
    },


    stopCountDown() {
      if (this.countdown.interval) {
        clearInterval(this.countdown.interval);
      }
      this.countdown.interval = null;
      this.countdown.hours = 0;
      this.countdown.minutes = 0;
      this.countdown.seconds = 0;
      this.countdown.ms = 0;
    }
  }
}
</script>


-------------------- style --------------------
<style scoped lang="less">
.ticket-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 100px;
  //position: fixed;
  left: 8px;
  margin-bottom: 5px;
  bottom: 234px;

  img {
    height: 24px;
    width: 24px;
  }
}

.ticket-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ticket-shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #151d36;
  opacity: .7;
  z-index: 1001;
}

.ticket {
  background: url("https://cdn.001ppt.cn/pc/img/ticket-pure.png");
  width: 300px;
  height: 330px;
  background-size: 100% 100%;
  z-index: 1002;
  position: relative;
}

.ticket-title {
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-align: center;
  position: absolute;
  top: 95px;
  left: 0;
  right: 0;
}

.ticket-sub-title {
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
}

.ticket-printer {
  position: absolute;
  top: 164px;
  left: 7px;
  width: 288px;
}

.ticket-desc {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleY(.9);
}

.ticket-desc-discount {
  font-size: 32px;
  font-weight: bolder;
  z-index: 19;
  transform: skew(-10deg);
  color: #ef6a52;
  padding-right: 14px;
}

.ticket-desc-content {
  color: #ef6a52;
  font-size: 12px;
  transform: skew(10deg);
}

.ticket-countdown-title {
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 225px;
  left: 0;
  right: 0;
}

.ticket-countdown {
  font-size: 12px;
  position: absolute;
  top: 245px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.ticket-countdown-box {
  display: flex;
  align-items: center;
}

.ticket-countdown-block {
  flex-shrink: 0;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffcf5;
  border-radius: 4px;
  color: #f00;
}

.ticket-countdown-text {
  flex-shrink: 0;
  font-size: 12px;
}

.ticket-btn {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
  text-align: center;

  button {
    border: none;
    background: #ea4945;
    color: #ffdfb5;
    font-size: 16px;
    font-weight: bolder;
    padding: 4px 24px;
    border-radius: 100px;
    cursor: pointer;
  }
}

.ticket-close-btn {
  text-align: center;
  margin-top: 340px;
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
  }
}

.close-model-img {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  img {
    width: 100px;
  }
}

.close-model-title {
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 8px;
}

.close-model-sub-title {
  text-align: center;
  margin-bottom: 16px;
}

.close-model-btns {
  display: flex;
  justify-content: center;

  button {
    width: 100px;
    height: 40px;
    background: #f3f3f5;
    border-radius: 100px;
    font-size: 12px;
    border: none;

    &.primary {
      margin-left: 16px;
      background: #151d36;
      color: #fff;
    }
  }
}
</style>
