<template>
  <div :class="{'show-publish': visibles.publish}">
    <div v-if="!hideHeader" id="home-header" class="home-header">
      <div class="home-header-icon" @click="clickSearch">
        <img src="https://cdn.001ppt.cn/h5/img/search_bold.svg" style="height: 20px;width: 20px">
      </div>
      <div class="home-header-tab-container" ref="tabContainer">
        <!--        <van-tabs v-model="currTab">-->
        <!--          <van-tab title="严审" name="/"></van-tab>-->
        <!--          <van-tab title="热榜" name="/hotRank"></van-tab>-->
        <!--          <van-tab name="/ai">-->
        <!--            <template #title>-->
        <!--              <img src="https://cdn.001ppt.cn/h5/ai/ai_tab.svg" alt=""-->
        <!--                   style="height: 15px;width: 35px;transform: translateY(2px)">-->
        <!--            </template>-->
        <!--          </van-tab>-->
        <!--        </van-tabs>-->
        <div class="tab-item" ref="homeTabItem" @click="currTab='/'">严审</div>
        <div class="tab-item" ref="hotRankTabItem" @click="currTab='/hotRank'">热榜</div>
        <div class="tab-item" ref="aiTabItem" @click="changeTab('/ai')">
          <img src="https://cdn.001ppt.cn/h5/ai/ai_tab_h5.png" alt="" ref="aiTabItemImg" class="ai_tab_h5">
        </div>
        <div class="active-line" v-if="style.tabLineTransformXMap[currTab]"
             :style="{transform: `translateX(${style.tabLineTransformXMap[currTab]}px)`}"></div>

      </div>
      <div class="home-header-icon right" @click="showShare">
        <i class="iconfont icon-share" v-if="$route.path === '/hotRank'"></i>
      </div>


    </div>
    <div v-if="!hideHeader" class="home-header-placeholder"></div>


    <common-share-sheet ref="commonShareSheet" @share="onShare"/>
  </div>
</template>


------------------ script ------------------
<script>
import {taskV2Api} from "../../../api/v2/taskV2Api";
import {copyToClipBoard, sleep} from "../../../config/util";
import {mapGetters} from "vuex";
import CommonShareSheet from "../../../components/commonShareSheet";

export default {
  components: {CommonShareSheet},
  props: ['hideHeader'],

  computed: {
    ...mapGetters(['userInfo']),
  },

  watch: {
    currTab() {
      if (this.$route.path !== this.currTab) {
        this.$router.push(this.currTab)
      }
    },

    '$route.path': {
      immediate: true,
      handler() {
        if (['/', '/ai', '/hotRank'].includes(this.$route.path)) {
          this.currTab = this.$route.path
        }
      }
    }
  },

  data() {
    return {
      visibles: {
        publish: false
      },
      config: {
        recommendAwardYanzhi: 0
      },
      currTab: '/',
      style: {
        tabLineTransformXMap: {
          '/': '',
          '/hotRank': '',
          '/ai': ''
        }
      }
    }
  },


  mounted() {
    this.loadRecommendAwardYanzhi()
    const query = this.$route.query
    this.initStyle()
  },


  methods: {

    clickSearch(){
      if(window.showHomeSearch){
        window.showHomeSearch()
      }else{
        this.$router.push('/')
        setTimeout(()=>{
          window.showHomeSearch()
        },200)
      }
    },

    changeTab(tab) {
      if (this.currTab === '/ai' && this.$route.path === '/ai') {
        if (window.clearAiChat) {
          window.clearAiChat()
        }
      }
      this.currTab = tab
    },

    async initStyle() {
      while (true) {
        const homeTabItem = this.$refs.homeTabItem
        const hotRankTabItem = this.$refs.hotRankTabItem
        const aiTabItem = this.$refs.aiTabItem
        if (!homeTabItem || !hotRankTabItem || !aiTabItem) {
          await sleep(200)
          continue
        }

        const tabContainerRect = this.$refs.tabContainer.getBoundingClientRect()
        const homeTabItemRect = homeTabItem.getBoundingClientRect()
        const hotRankTabItemRect = hotRankTabItem.getBoundingClientRect()
        const aiTabItemRect = aiTabItem.getBoundingClientRect()
        this.style.tabLineTransformXMap['/'] = (homeTabItemRect.left - tabContainerRect.left) + (homeTabItemRect.width / 4)
        this.style.tabLineTransformXMap['/hotRank'] = (hotRankTabItemRect.left - tabContainerRect.left) + (hotRankTabItemRect.width / 4)
        this.style.tabLineTransformXMap['/ai'] = (aiTabItemRect.left - tabContainerRect.left) + this.$refs.aiTabItemImg.getBoundingClientRect().width / 3.5
        break
      }
    },


    showShare() {
      this.$refs.commonShareSheet.show()
    },


    // 加载首次奖励严值
    async loadRecommendAwardYanzhi() {
      const res = await taskV2Api.findById(6);
      // console.log(res);
      this.config.recommendAwardYanzhi = res.value;
    },


    //复制
    copy() {
      let url = window.location.href;
      const splitChar = url.includes('?') ? '&' : '?'
      url += splitChar + 'tab=' + this.currTab

      if (this.userInfo.id) {
        if (window.location.href.indexOf('?') > -1) {
          url += '&shareUserId=' + this.userInfo.id;
        } else {
          url += '?shareUserId=' + this.userInfo.id;
        }
      }
      try {
        copyToClipBoard('灵感严选方案库 ' + url)
        this.$toast.success('复制链接成功');
      } catch (e) {
        this.$toast.error('复制失败');
      }
    },


    /**
     * 分享
     */
    onShare(code) {
      if (code === 'copyUrl') {
        this.copy();
      } else if (code === 'sharePost') {
        return this.$router.push({name: 'homeSharePost', query: this.$route.query});
      }

      this.$refs.commonShareSheet.hide()
    },

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.home-header {
  display: flex;
  align-items: center;
  height: 50px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1199;
}

.home-header-placeholder {
  height: 50px;
  background: #f4f4f4;
}

.home-header-icon {
  flex-shrink: 0;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-header-icon .iconfont {
  font-size: 18px;
}

.home-header-tab-container {
  width: calc(100vw - 54px - 80px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 4px;
  position: relative;

  .active-line {
    position: absolute;
    height: 3px;
    width: 20px;
    background: #151D36;
    border-radius: 100px;
    bottom: -14px;
    left: 0;
    transition: transform .3s;
  }
}

.tab-item {
  width: 40px;
  font-size: 14px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
}

.publish-plus {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #151D36;
  border-radius: 100px;
  font-weight: bolder;
  transition: transform .3s ease;
}

.publish {
  position: fixed;
  top: 50px;
  left: 2000px;
  right: 0;
  bottom: 0;
}

.publish-shade {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #151D36;
  opacity: .9;
}

.publish-panel {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  background: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 16px;
  transform: scale(0);
  transition: transform .1s ease-in-out;
}

.publish-btn {
  margin: 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.publish-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  background: #e44c4c;
  color: #FFF;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 100px;
}

.publish-text {
  font-size: 14px;
  margin-top: 8px;
}

.show-publish .publish {
  left: 0;
}

.show-publish .publish-plus {
  transform: rotate(45deg);
}

.show-publish .publish-panel {
  transform: scale(1);
}


.ai_tab_h5 {
  height: 15px;
  width: calc(15px * 512 / 120);
  position: absolute;
  left: 0;
}
</style>
